import { createGlobalStyle } from 'styled-components';

import * as svars from 'assets/style/variables';

export default createGlobalStyle`
  #root {
    height: 100%;
    width: 100%;
  }
  html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: ${svars.backgroundColorBase};
    line-height: initial;
    // This constraint is problematic when page is scrollable, as right side
    // of the page is overlayed by scrollbar
    // min-width: 100vw;
  }
  body {
    font-family: ${svars.fontFamilySansSerif};
    color: ${svars.fontColorBase};
  }
  i {
    font-style: normal;
  }
  input[type=number] {
    text-align: right !important;
  }

  .recharts-tooltip-wrapper {
    outline: none;
  }

  .tiptap {
    p {
      margin: 0;
    }
    a {
      /* standard link color */
      color: #007bff;
      text-decoration: underline;
    }
  }

  i.flag.es.ca:before,
  i.flag.catalonia:before {
    background-position: 0 -936px
  }

  .ui.modal > .header {
    // Make room for the close button
    padding-right: 2.5rem;
  }
  .ui.modal > .close {
    // Move the close button to the left
    right: 1.6rem;
  }

  /* Textarea laceholder color */
  /* NB : input placeholder color is defined in semantic ui variables */
  textarea::-webkit-input-placeholder, /* Webkit based */
  textarea::placeholder { /* Modern browsers */
    color: ${svars.placeholderColor};
  }
`;
